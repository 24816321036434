<template>
  <div id="app" data-app="true" :key="updateIndex" :class="browserClass">
    <ba-header :key="'header'+updateIndex"/>
    <router-view class="main-container" id="app-container" :key="updateIndex"/>
    <ba-footer :key="'footer'+updateIndex"/>
  </div>
</template>

<script>
  import BaFooter from '@/components/BaFooter';
  import BaHeader from '@/components/BaHeader';

  import { EventBus, Events } from '@/services/EventBus';

  import isMobileMixin from '@/mixins/isMobile';

  export default {
    metaInfo () {
      return {
        title: this.$t('app.meta.title'),
        meta: [
          // Set 'vmid' to prevent duplicate meta tags (https://github.com/nuxt/vue-meta/issues/143)
          { vmid: 'description', name: 'description', content: this.$t('app.meta.description') },
          { vmid: 'og:title', property: 'og:title', content: this.$t('app.meta.title') },
          { property: 'og:site_name', content: this.$t('app.meta.og.site-name') },
          { property: 'og:type', content: 'website' },
          { name: 'robots', content: 'index,follow' },
          { name: 'viewport', content: 'width=device-width,initial-scale=1.0' },
          { name: 'author', content: this.$t('app.meta.author') },
          { name: 'publisher', content: this.$t('app.meta.publisher') },
          { name: 'copyright', content: this.$t('app.meta.copyright') },
          { name: 'keywords', content: this.$t('app.meta.keywords') },
          { name: 'page-topic', content: this.$t('app.meta.page-topic') },
          { name: 'audience', content: this.$t('app.meta.audience') },
        ],
      };
    },
    components: {
      BaFooter,
      BaHeader,
    },
    data: () => ({
      updateIndex: 0,
      browserClass: '',
    }),
    mixins: [isMobileMixin],
    mounted: function () {
      this.setPageClass();
      EventBus.$on(Events.FORCE_UPDATE, () => {
        this.updateIndex = (this.updateIndex + 1) % 256;
      });
      const userAgent = navigator.userAgent.toLowerCase();
      if (userAgent.indexOf('safari') !== -1 && userAgent.indexOf('chrome') <= -1) {
        this.browserClass += ' safari';
      }
    },
    methods: {
      setPageClass: function () {
        if (this.$route.path === '/') {
          this.$root.$el.classList.add('page-home');
        } else {
          this.$root.$el.classList.remove('page-home');
        }
      },
    },
    watch: {
      '$route' () {
        this.setPageClass();
      },
    },
  };
</script>

<style lang="scss">
  @import "style/_init-vars-functions-mixins";
  .main-container {
    box-shadow: 0 -2px 4px 0 $gray-300;
    padding-bottom: 45px;
    @include media-breakpoint-up(lg) {
      box-shadow: none;
      padding-top: 42px;
      padding-bottom: 69px;
    }
  }
  @include media-breakpoint-down(md) {
    .page-home {
      .main-container {
        box-shadow: none;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        padding-top: 0;
      }
    }
  }
</style>

<i18n lang="yaml" locale="de">
  app.meta.title: 'Bewerber*innenbörse Campus Match - DHBW Duale Hochschule Baden-Württemberg Ravensburg'
  app.meta.description: 'Campus Match ist eine Plattform der DHBW Ravensburg und bringt Studieninteressierte und Ausbildungsunternehmen auf einfache Art zusammen.'
  app.meta.og.site-name: 'Bewerber*innenbörse Campus Match - DHBW Duale Hochschule Baden-Württemberg Ravensburg'
  app.meta.author: 'DHBW Duale Hochschule Baden-Württemberg Ravensburg'
  app.meta.publisher: 'DHBW Duale Hochschule Baden-Württemberg Ravensburg'
  app.meta.copyright: 'Campus Match ist ein Produkt der Brain Appeal GmbH, Mannheim'
  app.meta.keywords: 'Bewerbung, Studium, Job, Dual, Hochschule, Quiz'
  app.meta.page-topic: 'Bildung'
  app.meta.audience: 'Anfänger, Azubis, Erwachsene, Experten, Schüler, Studenten'
</i18n>
