var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      key: _vm.updateIndex,
      class: _vm.browserClass,
      attrs: { id: "app", "data-app": "true" },
    },
    [
      _c("ba-header", { key: "header" + _vm.updateIndex }),
      _vm._v(" "),
      _c("router-view", {
        key: _vm.updateIndex,
        staticClass: "main-container",
        attrs: { id: "app-container" },
      }),
      _vm._v(" "),
      _c("ba-footer", { key: "footer" + _vm.updateIndex }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }